import { get, post, excel } from '../utils/http'

// 获取批次
export function GetZHCPXMPageList(data) {
  return get('zhcp/SY_ZHCP_XM/GetPageList/', data)
}

// 根据ID获取
export function getZHCPXMFormData(data) {
  return get('zhcp/SY_ZHCP_XM/GetFormData/', data)
}

// 保存项目
export function saveZHCPXM(data) {
  return post('zhcp/SY_ZHCP_XM/Save/', data)
}

// 保存申请
export function saveZHCPSQ(data) {
  return post('zhcp/SY_ZHCP_XSSQ/Save/', data)
}

// 获取加减分
export function GetJJFPageList(data) {
  return get('zhcp/SY_ZHCP_XSSQ/GetJJFPageList/', data)
}

// 获取待审批
export function GetDSPPageList(data) {
  return get('zhcp/SY_ZHCP_XSSQ/GetDSPPageList/', data)
}

// 获取详细信息
export function GetZHCPFormData(data) {
  return get('zhcp/SY_ZHCP_XSSQ/GetDetailData/', data)
}

// 获取详细信息
export function saveSPInfo(data) {
  return post('zhcp/SY_ZHCP_XSSQ/SaveSP/', data)
}

// 保存辅导员减分
export function saveZHCPJF(data) {
  return post('zhcp/SY_ZHCP_XSSQ/SaveJF/', data)
}

// 获取加减分管理
export function GetJFGLPageList(data) {
  return get('zhcp/SY_ZHCP_XSSQ/GetJFGLPageList/', data)
}

// 提交计算
export function SaveJS(data) {
  return post('zhcp/SY_ZHCP_DF/Save/', data)
}

// 获取当前数量
export function GetCurrent(data) {
  return get('zhcp/SY_ZHCP_DF/GetCurrent/', data)
}

// 保存辅导员加分
export function saveFDYJF(data) {
  return post('zhcp/SY_ZHCP_FDYJF/Save/', data)
}

// 获取综合测评得分查询数据
export function GetDFCXPageList(data) {
  return get('zhcp/SY_ZHCP_DF/GetPageList/', data)
}

// 获取综合测评得分查询数据
export function GetXSDFCXPageList(data) {
  return get('zhcp/SY_ZHCP_DF/GetXSCXPageList/', data)
}

// 获取相同项目相同学期的加分
export function getJFSQList(data) {
  return get('zhcp/SY_ZHCP_XSSQ/GetSQListById/', data)
}

// 获取相同项目相同学期的加分
export function saveCX(data) {
  return post('zhcp/SY_ZHCP_XSSQ/SaveCX/', data)
}

// 导出信息
export function exportExcel(data) {
  return excel('zhcp/SY_ZHCP_DF/ExportListExcel/', data)
}

// 获取测评排名
export function getZHCPPM(data) {
  return get('zhcp/SY_ZHCP_DF/GetZHCPPM/', data)
}

// 获取项目子项列表
export function getChildList(data) {
  return get('zhcp/SY_ZHCP_XM/GetChildList/', data)
}

// 保存项目
export function saveZHCPXMChild(data) {
  return post('zhcp/SY_ZHCP_XM/SaveChild/', data)
}

// 获取项目子项表单
export function getChildFormData(data) {
  return get('zhcp/SY_ZHCP_XM/GetChildFormData/', data)
}

// 删除项目子项
export function deleteChild(data) {
  return post('zhcp/SY_ZHCP_XM/DeleteChild/', data)
}

// 学生获取综测单
export function studentGetListByXN(data) {
  return get('zhcp/SY_ZHCP_XSSQ/StudentGetListByXN/', data)
}
